import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import Record from "./record";
import {
  API_GET_IMG_URL,
  API_SEND_MSG,
  API_START_CHAT,
  API_UPLOAD_IMG,
} from "../../api/chat.service";
import {
  setIsProductSelected,
  setImage,
  setVideo,
  setIsFileSelect,
  setDocument,
  setIsImageSelected,
  setIsVideoSelected,
  changeTicketID,
  updateUserStatus,
  changeIsRecAudioSelect,
  setFiles,
  showFilelimitWarning,
  clearReplyMessage,
  setReplyMessage
} from "../../reducers/enqChatSlice";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useForm, Controller } from "react-hook-form";

import { InputTextarea } from "primereact/inputtextarea";
import { CustomButton } from "../../components/CustomButton";
import { ReactComponent as SendButton } from "../../assets/svgIcons/svgIcons/sendbuttonicon.svg";
import { ReactComponent as SmileIcon } from "../../svg/smile.svg";
import { ReactComponent as PlusIcon } from "../../svg/plus.svg";

import { isTimeOver } from "../../utils/chatUtils";
import StartConversation from "./StartConversation";
import ChangeTicketStatus from "./ChangeTicketStatus";
import CONSTANTS from "../../config/Constants";
import CustomSaveModel from "../../components/CustomeSaveModel";
import QuickNotes from "./QuickNotes";
import { compressImg } from "../../utils/templateUtils";
import FilesDragAndDrop from "./FilesDragAndDrop";
import { v4 as uuid } from "uuid";
import {
  DOC_INPUT_EXTENTIONS,
  IMAGE_INPUT_EXTENTIONS,
  NO_OF_VIDEOS_AND_IMAGES,
  VIDEO_INPUT_EXTENTIONS,
} from "../../config/FileConstants";
import { useLayout } from "../../context/LayoutContext";
import Message from "./Message";
import ReplayMessageModal from "../../components/ReplyMessageModal/ReplyMessageModal.js";

const Input = () => {
  const { chatId, user, isRecAudioSelect, forwardMessagesFlag,messageForReply,replyMessage, } = useSelector(
    (state) => state.enqChats
  );

  const closedTicketStatus = user?.ticketStatus === "Closed";
  const [onClickPlusIcon, setOnClickPlusIcon] = useState(false);
  const [isDisbaleSend, setIsDisableSend] = useState(false);
  const [openEmoji, setOpenEmoji] = useState(false);
  const divFileAndPhotoRef = useRef();
  const divPlusIconRef = useRef();
  const dispatch = useDispatch();
  const textAreaRef = useRef(null);
  const { toast } = useLayout(null);
  const defaultValues = {
    content: "",
  };

  const { control, reset, handleSubmit, setValue, watch } = useForm({
    defaultValues,
  });
  const watchContent = watch("content"); // Replace with actual field name
  const onSubmit = (data) => {
    handleSend(data.content);
  };

  const handleUpload = async (e, type) => {
    let fileArray = Array.from(e.files).slice(0, 5);
    let files = [];
    var limit = 0;
    if (e.files.length > NO_OF_VIDEOS_AND_IMAGES) {
      dispatch(
        showFilelimitWarning({
          filesLength: e.files.length,
          filesLengthWarning: true,
        })
      );
    }
    for (const x of fileArray) {
      const unique_id = uuid();
      limit++;
      if (limit > NO_OF_VIDEOS_AND_IMAGES) {
        break;
      }

      try {
        // Attempt to read the file
        await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve();
          reader.onerror = () => reject(new Error("File read error"));
          reader.readAsArrayBuffer(x);
        });
        if (type === "media") {
          if (
            x?.type == "image/png" ||
            x?.type == "image/jpeg" ||
            x?.type == "image/jpg"
          ) {
            const compressedFile = await compressImg(x);
            let data = new File([compressedFile], compressedFile.name, {
              type: compressedFile.type,
            });
            files = [
              ...files,
              {
                id: unique_id.slice(0, 8),
                data,
                type: "image",
              },
            ];
          } else if (x?.type == "video/mp4" || x?.type == "video/mp3") {
            files = [
              ...files,
              {
                id: unique_id.slice(0, 8),
                data: x,
                type: "video",
              },
            ];
          }
        } else if (type === "doc") {
          files = [
            ...files,
            {
              id: unique_id.slice(0, 8),
              data: x,
              type: "document",
            },
          ];
        } else {
          if (
            x?.type == "image/png" ||
            x?.type == "image/jpeg" ||
            x?.type == "image/jpg"
          ) {
            const compressedFile = await compressImg(x);
            let data = new File([compressedFile], compressedFile.name, {
              type: compressedFile.type,
            });
            files = [
              ...files,
              {
                id: unique_id.slice(0, 8),
                data,
                type: "image",
              },
            ];
          } else if (x?.type == "video/mp4" || x?.type == "video/mp3") {
            files = [
              ...files,
              {
                id: unique_id.slice(0, 8),
                data: x,
                type: "video",
              },
            ];
          } else {
            files = [
              ...files,
              {
                id: unique_id.slice(0, 8),
                data: x,
                type: "document",
              },
            ];
          }
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          detail: `Error processing file: ${x.name}.`,
        });
        files = [
          ...files,
          {
            id: unique_id.slice(0, 8),
            data: x,
            type: "document",
          },
        ];
      }
    }
    dispatch(setFiles(files));
    dispatch(setIsFileSelect(true));
  };
  const handelAudioSend = async () => {
    setIsDisableSend(true);
    let name = Math.floor(Math.random() * 10000000) + ".mpeg";
    var file = new File([blobData], `${name}`, { type: "audio/mpeg" });
    try {
      if (blobData) {
        let data = {
          payload: {
            //type: "audio",
          },
        };
        if (replyMessage) {
          data.payload.context = {
            msgId: messageForReply.gsId, // Assuming messageForReply is defined somewhere
          };
        }
        if (closedTicketStatus)
        {
          data.customerMobile = user?.mob;
        } else {
          data.destinationNo = user?.mob;
        }

        const respUrl = await API_GET_IMG_URL(user);

        const UploadUrl = respUrl.data.src;
        if (UploadUrl) {
          await API_UPLOAD_IMG(UploadUrl, file, "audio/mpeg");

          data.payload.type = "audio";
          data.payload.url = respUrl.data.url;

          //data.payload.previewUrl = respUrl.data.url;
          //data.payload.fileName = name;
          //data.payload.id = name
        }
        const resp = !closedTicketStatus
          ? await API_SEND_MSG(user?.ticketId, data).then(() => {
            reset();
            setblobUrl(null);
            setBlobData(null);
            setAudioChunks([]);
            dispatch(changeIsRecAudioSelect(false));
            setIsDisableSend(false);
            dispatch(clearReplyMessage({}));
          })
          : await API_START_CHAT(data).then((e) => {
            dispatch(changeTicketID(e.data.id));
            dispatch(updateUserStatus({ ticketStatus: e.data.ticketStatus }));
            dispatch(changeIsRecAudioSelect(false));
            reset();
            setblobUrl(null);
            setBlobData(null);
            setAudioChunks([]);
            setIsDisableSend(false);
            dispatch(clearReplyMessage({}));
          });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        detail: error.response.data.message,
      });
    }
  };

  const handleRecAudioSend = () => {
    if (blobURL !== null) {
      if (closedTicketStatus) {
        setShowSave(true);
      } else {
        handelAudioSend();
      }
    }
  };

  const handleSend = async (textMsg) => {
    setOpenEmoji(false);
    if (user?.ticketId && user.ticketStatus !== "Closed") {
      try {
        const data = {
          destinationNo: user?.mob,
          payload: {
            type: "text",
            text: textMsg,
            ...(replyMessage && {
              context: {
                msgId: messageForReply.gsId,
              }
            }),
          },
        };
        const resp = await API_SEND_MSG(user?.ticketId, data)
      } catch (error)
      {
        toast.current.show({
          severity: "error",
          detail: error.response.data.message,
        });
      }
    } 
    else {
      try {
        const data = {
          customerMobile: user?.mob,
          payload: {
            type: "text",
            text: textMsg,
            ...(replyMessage && {
              context: {
                msgId: messageForReply.gsId,
              }
            }),
          },
        };
        const resp = await API_START_CHAT(data).then((e) => {
          dispatch(changeTicketID(e.data.id));
        });
      } catch (error)
      {
        toast.current.show({
          severity: "error",
          detail: error.response.data.message,
        });
      }
    }
    reset();
    dispatch(clearReplyMessage({}));
  };
  const handelProdSend = () => {
    dispatch(setIsProductSelected(true));
  };

  function OptionsComponenet() {
    return (
      <>
        <div
          ref={divFileAndPhotoRef}
          className="flex __br justify-content-center align-items-center __sendOptionsAfter "
        >
          <div className="flex  flex-column justify-content-center align-items-center ">
            <div className="__itemDiv">
              <input
                type="file"
                style={{ display: "none" }}
                id="video"
                onChange={(e) => handleUpload(e.target, "media")}
                accept={`${IMAGE_INPUT_EXTENTIONS} ,${VIDEO_INPUT_EXTENTIONS}`}
                multiple
              />

              <label
                htmlFor="video"
                className="cursor-pointer flex align-items-center gap-1"
              >
                <i className="pi pi-paperclip" />
                <span>Photos & Videos</span>
              </label>
            </div>
            <div className="__itemDiv">
              <label
                onClick={handelProdSend}
                className="cursor-pointer flex align-items-center gap-1"
              >
                <i className="pi pi-qrcode" />
                <span>Products</span>
              </label>
            </div>

            <div className="__itemDiv">
              <input
                type="file"
                style={{ display: "none" }}
                id="Documents"
                onChange={(e) => handleUpload(e.target, "doc")}
                multiple
                accept={`${DOC_INPUT_EXTENTIONS}`}
              />
              <label
                htmlFor="Documents"
                className="cursor-pointer flex align-items-center gap-1"
              >
                <i className="pi pi-file" />
                <span>Documents</span>
              </label>
            </div>
          </div>
        </div>
      </>
    );
  }

  function OnClickPlusIcon(e) {
    setOnClickPlusIcon(!onClickPlusIcon);
  }

  const handleEmojiClick = (e) => {
    const emoji = e.native;
    const input = textAreaRef.current;
    const textAreavalue = watchContent || "";
    if (!input) return;

    const { selectionStart, selectionEnd } = input;
    const newValue =
      textAreavalue.substring(0, selectionStart) +
      emoji +
      textAreavalue.substring(selectionEnd);
    // Set the cursor position after the inserted text
    const newPosition = selectionStart + emoji.length;
    textAreaRef.current.value = newValue;
    input.setSelectionRange(newPosition, newPosition);
    input.focus();
    setValue("content", newValue);
    setOpenEmoji(true);
  };

  const handleClickOutside = (event) => {
    if (
      divFileAndPhotoRef.current &&
      !divFileAndPhotoRef.current.contains(event.target) &&
      divPlusIconRef.current &&
      !divPlusIconRef.current.contains(event.target)
    ) {
      if (!onClickPlusIcon) {
        setOnClickPlusIcon(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const message = useRef(null);
  const [showSave, setShowSave] = useState(false);
  const SaveModel = () => {
    return (
      <CustomSaveModel
        showSave={showSave}
        header={alertText()}
        dialogFooter={dialogFooter}
        onHide={onHide}
      />
    );
  };
  const alertText = () => {
    return <div>{CONSTANTS.ENQ_CHAT_TICKET_CONFIRMATION}</div>;
  };

  const handleDialogAction = () => {
    if (isRecAudioSelect) {
      handelAudioSend();
    } else {
      onSubmit(message?.current);
    }
    setShowSave(false);
  };

  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={() => {
            setShowSave(false);
          }}
        />
        <CustomButton
          label="Confirm"
          varient="filled"
          onClick={handleDialogAction}
        />
      </div>
    );
  };
  const onHide = () => {
    setShowSave(false);
  };
  const sendMessage = (data) => {
    message.current = data;
    if (user?.ticketId && user.ticketStatus !== "Closed") {
      onSubmit(message.current);
    } else {
      setShowSave(true);
    }
  };
  const handleQuickNoteClick = (qnote) => {
    setValue("content", qnote);
  };

  //audio-----------------------------------
  const [isRecording, setIsRecording] = useState(false);
  const [blobURL, setblobUrl] = useState(null);
  const [blobData, setBlobData] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const recRef = useRef(null);
  const mediaStream = useRef(null);
  const [timer, setTimer] = useState(0);
  const [isBlocked, setIsBlocked] = useState(false);
  useEffect(() => {
    let intervalId;

    if (isRecording) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
      setTimer(0);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isRecording]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  // const startRecording1 = async () => {
  //   try {
  //     dispatch(changeIsRecAudioSelect(true));
  //     await startusingBrowserMicrophone(true);
  //   } catch (error) {
  //     // Handle permission denied or other errors
  //     console.error("Error accessing microphone:", error);
  //     // alert("Permission denied or error accessing microphone");
  //   }
  // };

  // function handlerFunction(stream) {
  //   recRef.current = new MediaRecorder(stream);
  //   recRef.current.start();
  //   setIsRecording(true);
  //   recRef.current.ondataavailable = (e) => {
  //     audioChunks.push(e?.data);
  //     if (recRef.current?.state === "inactive") {
  //       let blob = new Blob(audioChunks, { type: "audio/mp3" });
  //       setBlobData(blob);
  //     }
  //   };
  // }

  // async function startusingBrowserMicrophone(boolean) {
  //   await getUserMedia({ audio: boolean }).then((stream) => {
  //     mediaStream.current = stream;
  //     if (stream) {
  //       handlerFunction(stream);
  //     }
  //   });
  // }

  const stopRecording1 = () => {
    if (recRef.current) {
      recRef.current.stop();
      setIsRecording(false);

      if (mediaStream.current) {
        mediaStream.current.getTracks().forEach((track) => track.stop());

        mediaStream.current = null;
      } else {
        console.warn("No active recording to stop.");
      }
    }
  };

  const handlePaste = (e) => {
    if (e.clipboardData.files.length) {
      handleUpload(e.clipboardData);
    }
  };
  const hideReplyHandler = () => {
    // dispatch(clearReplyMessage());
    dispatch(setReplyMessage({replyMessage: false}))
  };

  return (
    <>
      <SaveModel />

      {!forwardMessagesFlag && chatId && (
        <>
          {onClickPlusIcon && <OptionsComponenet />}
          <div
            className={`flex w-full flex-column  __inputContainer`}
            style={{ background: "#f8f8fc" }}
          >
            {replyMessage && (
                    <div className=" px-3  py-2 w-full flex justify-content-between align-items-center gap-3 replyContainer ">
                      <div className="w-full replyInnerContainer px-2"  >
                        <ReplayMessageModal
                        message={messageForReply}
                        key_id={messageForReply?.message.gsId}/>
                      </div>
                      <div onClick={hideReplyHandler}  ><i className="pi pi-times" style={{ fontSize: '1.15rem' }}></i></div>
                    </div>
                  )}
            <div
              className={`flex flex-column w-full   md:flex-row   ${user?.ticketId
                ? "justify-content-between"
                : "justify-content-start"
                }  align-items-end  py-2 px-3`}
            >
              {isTimeOver(user?.enqStartedAt, 24 * 60 * 60 * 1000) ? (
                <StartConversation />
              ) : (
                <>
                  <div className="flex w-full  md:w-auto justify-content-between gap-3 relative  ">
                    {/* emoji modal ++++++++++++++++++ */}
                    {openEmoji && (
                      <div
                        className="absolute border-1"
                        style={{
                          bottom: 90,
                          zIndex: 3,
                        }}
                      >
                        <Picker
                          data={data}
                          onEmojiSelect={handleEmojiClick}
                          onClickOutside={() => setOpenEmoji(false)}
                        />
                      </div>
                    )}
                    {/* emoji & +   +++++++++++++++++++++++ */}
                    <div className="flex  __EmojiItems gap-1 mr-2">
                      <div
                        className="__mb"
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenEmoji((prev) => !prev);
                        }}
                      >
                        <span className="__Items p-2">
                          <SmileIcon />
                        </span>
                      </div>
                      <div
                        ref={divPlusIconRef}
                        className={`cursor-pointer  p-2 ${
                          !onClickPlusIcon ? "" : "__backC "
                        }`}
                        onClick={OnClickPlusIcon}
                      >
                        <div
                          className={`${
                            !onClickPlusIcon ? " __ItemsAdd" : "__ItemsCancel"
                          }`}
                        >
                          <PlusIcon />
                        </div>
                      </div>
                    </div>
                    {/* Record QuickNote Ticket Status modal ++++++++++++++ */}
                    {user?.ticketId && (
                      <div className="w-2 flex align-items-center min-w-min mb-1 md:hidden">
                        <Record
                          setblobUrl={setblobUrl}
                          setBlobData={setBlobData}
                          isRecording={isRecording}
                          setIsRecording={setIsRecording}
                        />
                        <QuickNotes handleClick={handleQuickNoteClick} />
                        <ChangeTicketStatus handleSend={handleSend} />
                      </div>
                    )}
                  </div>
                  {/* Inputfiled  ++++++++++++++++++++++++ */}
                  <form
                    className={`w-full md:w-9  relative`}
                    onSubmit={handleSubmit(sendMessage)}
                  >
                    {/* {replyMessage && (
                    <div className="bg-blue-100 px-2 w-full flex justify-content-between ">
                      <div className="w-12 flex justify-content-start" >
                      <Message
                        message={messageForReply}
                        key_id={messageForReply?.message.gsId}
                      />
                      </div>
                      <div onClick={hideReplyHandler} className="pt-2" ><i className="pi pi-times" style={{ fontSize: '1.15rem' }}></i></div>
                    </div>
                  )} */}
                    <FilesDragAndDrop onUpload={handleUpload}>
                      <div className="inputBox relative w-full">
                        <Controller
                          name="content"
                          className="w-full"
                          control={control}
                          rules={{ required: "Customer name is required." }}
                          render={({ field, fieldState }) => (
                            <InputTextarea
                              onPaste={handlePaste}
                              id={field.content}
                              className="w-full h-auto "
                              rows={3}
                              cols={30}
                              autoResize
                              placeholder={
                                !isRecording && !blobURL
                                  ? "Enter your message..."
                                  : ""
                              }
                              {...field}
                              ref={textAreaRef}
                            />
                          )}
                        />

                        <div
                          className={`absolute sendbutton flex justify-content-center align-items-center `}
                          style={{ bottom: "10px", right: "5px" }}
                        >
                          {blobData && (
                            <div className="flex">
                              <audio
                                src={blobURL}
                                id="audioElement"
                                className=""
                                style={{ width: "225px" }}
                                controls
                              ></audio>
                              {!isDisbaleSend && (
                                <button
                                  icon="pi pi-trash"
                                  className="mx-1 __delBtn  "
                                  onClick={() => {
                                    dispatch(changeIsRecAudioSelect(false));
                                    setBlobData(null);
                                    setblobUrl(null);
                                  }}
                                >
                                  <i class="pi pi-trash"></i>
                                </button>
                              )}
                            </div>
                          )}

                          {isRecording ? (
                            <div className="mr-1 __audioGif flex justify-content-center align-items-center">
                              <div className="mr-1">
                                <img src="./assets/Audio.gif" />
                                <img src="./assets/Audio.gif" />
                                <img src="./assets/Audio.gif" />
                                <img src="./assets/Audio.gif" />
                              </div>

                              <div>
                                <span>{formatTime(timer)}</span>
                              </div>
                            </div>
                          ) : (
                            <button
                              type={blobURL != null ? "" : "submit"}
                              onClick={handleRecAudioSend}
                              disabled={isDisbaleSend}
                            >
                              {isDisbaleSend ? (
                                <i
                                  class="pi pi-spin pi-spinner"
                                  style={{ color: "black", width: "1.5rem" }}
                                ></i>
                              ) : (
                                <SendButton />
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                    </FilesDragAndDrop>
                  </form>
                  <div className="w-2 min-w-min mb-1 hidden md:flex align-items-center  ml-2">
                    <div>
                      {user?.ticketId && (
                        <Record
                          setblobUrl={setblobUrl}
                          setBlobData={setBlobData}
                          isRecording={isRecording}
                          setIsRecording={setIsRecording}
                        />
                      )}
                    </div>
                    <QuickNotes handleClick={handleQuickNoteClick} />
                    {user?.ticketId && (
                      <ChangeTicketStatus handleSend={handleSend} />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Input;
