import React, { Component } from "react";
import * as markerjs2 from "markerjs2";
import * as cropro from "cropro";
import './index.css'
import {CustomButton} from '../../components/CustomButton/index';

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

class SkalebotImageEditor extends Component {
  constructor(props) {
    super(props);
    // Define the ref
    this.imgRef = React.createRef();
  }

  showMarkerArea() {
    if (this.imgRef.current !== null) {
      // create a marker.js MarkerArea
      const markerArea = new markerjs2.MarkerArea(this.imgRef.current);
      // attach an event handler to assign annotated image back to our image element
      markerArea.addEventListener("render", (event) => {
        if (this.imgRef.current) {
          this.imgRef.current.src = event.dataUrl;
          const fileName = this.props.image.data.name; // Desired file name with extension
          const newImageFile = dataURLtoFile(event.dataUrl,fileName );
         
         this.props.updateImage({...this.props.image, data:newImageFile })
        }
      });
      // launch marker.js
      markerArea.settings.displayMode = "popup";
      markerArea.show();
    }
  }

  showCropArea() {
    if (this.imgRef.current !== null) {
      // create a CropArea
      const cropArea = new cropro.CropArea(this.imgRef.current);
      // attach an event handler to assign cropped image back to our image element
      cropArea.addRenderEventListener((dataUrl) => {
        if (this.imgRef.current) {
          this.imgRef.current.src = dataUrl;
          const fileName = this.props.image.data.name; // Desired file name with extension
          const newImageFile = dataURLtoFile(dataUrl,fileName );
         
         this.props.updateImage({...this.props.image, data:newImageFile })
        }
      });
     
      cropArea.displayMode="popup"
      cropArea.isGridVisible = false;
      cropArea.show();
    }
  }

  render() {
    const { image} = this.props;
    return (
      <div className="relative">
        <div className="w-full absolute img-edit-button-container flex justify-content-end align-intems-center pt-2 pr-2 gap-3">
          <CustomButton varient="outline" label={'Crop'} onClick={() => this.showCropArea()}/>
          <CustomButton varient="outline" label={'Edit'} onClick={() => this.showMarkerArea()}/>
        </div>
        <img
          ref={this.imgRef}
          src={URL.createObjectURL(image.data)}
          alt="sample"
          style={{ width: "100%", display: "block" }}
          crossOrigin="anonymous" // Add crossOrigin here
        />
      </div>
    );
  }
}
export default SkalebotImageEditor;
