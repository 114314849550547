import { Galleria } from "primereact/galleria";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import {
  deleteFiles,
  setlockUpload,
  showFilelimitWarning,
} from "../../reducers/enqChatSlice";
import { useEffect, useState } from "react";
import videoIcon from "../../assets/video.png";
import longvideoIcon from "../../assets/longvideo.png";
import { ProgressBar } from "primereact/progressbar";
import { fileSizeInMB } from "../../utils/commonUtils";
import {
  MAX_DOCUMENT_SIZE,
  MAX_IMAGE_SIZE,
  MAX_VIDEO_SIZE,
} from "../../config/FileConstants";
import { Tooltip } from "primereact/tooltip";
import { useLayout } from "../../context/LayoutContext";
import SkalebotImageEditor from "../SkalebotImageEditor";
import { updateFiles } from "../../reducers/enqChatSlice";
function Fileviewer({
  fileuploadpercent,
  setFileuploadpercent,
  index,
  setIndex,
}) {
  const [items, setItems] = useState([]);
  const { files, fileSending, filesLengthWarning, filesLength } = useSelector(
    (state) => state.enqChats
  );
  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
    },
    {
      breakpoint: "960px",
      numVisible: 4,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];
  const dispatch = useDispatch();
  var lastindex = "";
  const filedelete = (e, id) => {
    if (files.length - 1 === index) {
      setIndex((x) => --x);
    }
    e.stopPropagation();
    dispatch(deleteFiles(id));
  };

  const { toast } = useLayout();
  useEffect(() => {
    if (filesLengthWarning) {
      toast.current.show({
        severity: "warn",
        detail: "Maximum 5 files allowed. Please select max 5 files to share.",
        life: 3000,
      });
      dispatch(
        showFilelimitWarning({
          filesLength: files.length,
          filesLengthWarning: false,
        })
      );
    }
  }, [filesLengthWarning]);

  function handleUpdatedChange(img) {
    dispatch(updateFiles(img));
  }

  const itemTemplate = (item) => {
    switch (item?.type) {
      case "image":
        return (
          <div style={{ width: "100%", maxWidth: "800px" }}>
            {!item.uploadable && (
              <div
                className="blur-screen"
                style={{
                  minWidth: "100%",
                }}
              >
                <div className="error-icon-box-item">
                  <i className="pi pi-info s-file-error text-7xl"></i>
                </div>
                <div className="error-content">
                  File size exceeds {MAX_IMAGE_SIZE}MB limit
                </div>
              </div>
            )}
            <SkalebotImageEditor
              image={item}
              updateImage={handleUpdatedChange}
            />
          </div>
        );
      case "video":
        let url = item?.data;
        return !fileSending ? (
          <div className="relative">
            {!item.uploadable && (
              <div
                className="blur-screen"
                style={{
                  minWidth: "100%",
                }}
              >
                <div className="error-icon-box-item" style={{ left: "44%" }}>
                  <i className="pi pi-info s-file-error text-7xl"></i>
                </div>
                <div className="error-content">
                  File size exceeds {MAX_VIDEO_SIZE}MB limit
                </div>
              </div>
            )}
            <video
              src={URL.createObjectURL(url)}
              alt={url}
              style={{
                width: "100%",
                display: "block",
                maxWidth: "800px",
                maxHeight: "400px",
              }}
              id="videoPlayer"
              controls
            />
          </div>
        ) : (
          <div style={{ maxWidth: "800px" }}>
            <img
              src={longvideoIcon}
              style={{
                width: "100%",
                display: "block",
                maxWidth: "800px",
                maxHeight: "400px",
                aspectRatio: "16/9",
              }}
            />
          </div>
        );
      case "document":
        return (
          <div
            className="flex flex-column justify-content-center align-items-center gap-2"
            style={{ maxWidth: "800px", height: "400px" }}
          >
            {!item.uploadable && (
              <div className="blur-screen" style={{ width: "400px" }}>
                <div
                  className="error-icon-box-item"
                  style={{ left: "39%", top: "28%" }}
                >
                  <i className="pi pi-info s-file-error text-7xl"></i>
                </div>
                <div className="error-content">
                  File size exceeds {MAX_DOCUMENT_SIZE}MB limit
                </div>
              </div>
            )}
            <i
              className="pi pi-file"
              style={{ fontSize: "100px", opacity: 0.3 }}
            ></i>
            <span>{item?.data?.name}</span>
            <span className="mt-3" style={{ opacity: 0.5, color: "gray" }}>
              No Preview available
            </span>
            {
              <span style={{ opacity: 0.5, color: "gray", fontSize: "18px" }}>
                {item?.data?.size / 1000} kb
              </span>
            }
          </div>
        );
    }
  };
  const showloader = (id) => {
    if (fileSending) {
      return true;
    } else {
      return false;
    }
  };
  const thumbnailTemplate = (item) => {
    switch (item?.type) {
      case "image":
        return (
          <div
            className={`relative file-body file-body-template m-1 h${item.id}`}
          >
            {!item.uploadable && (
              <div className="error-icon-box">
                <i className="pi pi-info s-file-error"></i>
              </div>
            )}

            <div
              className="absolute flex align-items-center justify-content-center wb-delete-icon cursor-pointer bg-black-alpha-30 z-1 text-white p-1  border-circle"
              style={{
                width: "16px",
                height: "16px",
                right: "5px",
                top: "5px",
              }}
              onClick={(e) => filedelete(e, item.id)}
            >
              <i
                className="pi pi-times  center"
                style={{
                  fontSize: "10px",
                  paddingLeft: "1px",
                }}
              ></i>
            </div>
            <img
              src={URL.createObjectURL(item.data)}
              alt={item?.image?.name}
              style={{ display: "block", width: "75px", height: "75px" }}
            />
            {showloader(item.id) && (
              <ProgressBar
                color={"#30b3d5"}
                value={fileuploadpercent[item.id]}
                className="w-full image-upload-loader"
                style={{ height: "10px", fontSize: "0.6rem" }}
              />
            )}
            {!item.uploadable && (
              <Tooltip
                target={`.h${item.id}`}
                position="bottom"
                content={`File size exceed`}
              />
            )}
          </div>
        );
      case "video":
        return (
          <div
            className={`relative file-body file-body-template m-1 h${item.id}`}
          >
            {!item.uploadable && (
              <div className="error-icon-box">
                <i className="pi pi-info s-file-error"></i>
              </div>
            )}
            <div
              className="absolute flex align-items-center wb-delete-icon cursor-pointer z-1 justify-content-center bg-black-alpha-30 text-white p-1  border-circle"
              style={{
                width: "16px",
                height: "16px",
                right: "5px",
                top: "5px",
              }}
              onClick={(e) => filedelete(e, item.id)}
            >
              <i
                className="pi pi-times cursor-pointer center"
                style={{
                  fontSize: "10px",
                  paddingLeft: "1px",
                }}
              ></i>
            </div>
            <div>
              <img
                src={videoIcon}
                style={{
                  display: "block",
                  width: "75px",
                  height: "75px",
                  pointerEvents: "none",
                }}
              />
            </div>
            {showloader(item.id) && (
              <ProgressBar
                color={"#30b3d5"}
                value={fileuploadpercent[item.id]}
                className="w-full image-upload-loader"
                style={{ height: "10px", fontSize: "0.6rem" }}
              />
            )}
            {!item.uploadable && (
              <Tooltip
                target={`.h${item.id}`}
                position="bottom"
                content={`File size exceed`}
              />
            )}
          </div>
        );
      case "document":
        return (
          <div
            id={item.id}
            className={`relative file-body file-body-template  m-1 h${item.id}`}
          >
            {!item.uploadable && (
              <div className="error-icon-box">
                <i className="pi pi-info s-file-error"></i>
              </div>
            )}

            <div
              className="absolute flex align-items-center z-1 wb-delete-icon cursor-pointer justify-content-center bg-black-alpha-30 text-white p-1  border-circle"
              style={{
                width: "16px",
                height: "16px",
                right: "5px",
                top: "5px",
              }}
              onClick={(e) => {
                filedelete(e, item.id);
              }}
            >
              <i
                className="pi pi-times center"
                style={{
                  fontSize: "10px",
                  paddingLeft: "1px",
                }}
              ></i>
            </div>
            <div
              className="flex flex-column justify-content-center align-items-center gap-2"
              style={{ display: "block", width: "75px", height: "75px" }}
            >
              <i
                className="pi pi-file"
                style={{ fontSize: "30px", opacity: 0.3 }}
              />
            </div>
            {showloader(item.id) && (
              <ProgressBar
                color={"#30b3d5"}
                value={fileuploadpercent[item.id]}
                className="w-full image-upload-loader"
                style={{ height: "10px", fontSize: "0.6rem" }}
              />
            )}
            {!item.uploadable && (
              <Tooltip
                target={`.h${item.id}`}
                position="bottom"
                content={`File size exceed`}
              />
            )}
          </div>
        );
    }
  };
  useEffect(() => {
    let fileArray = [];
    let lockFlag = false;
    for (let file of files) {
      // filesize check
      let uploadable = false;
      switch (file.type) {
        case "image":
          uploadable = fileSizeInMB(file.data) < MAX_IMAGE_SIZE;
          break;
        case "video":
          uploadable = fileSizeInMB(file.data) < MAX_VIDEO_SIZE;
          break;
        default:
          uploadable = fileSizeInMB(file.data) < MAX_DOCUMENT_SIZE;
      }
      if (!uploadable) {
        lockFlag = true;
      }
      fileArray = [...fileArray, { ...file, uploadable }];
      setFileuploadpercent((x) => {
        return { ...x, [file.id]: 0 };
      });
    }
    dispatch(setlockUpload(lockFlag));
    setItems([...fileArray]);
  }, [files]);
  return (
    <div>
      <Galleria
        value={items}
        activeIndex={index}
        onItemChange={(e) => setIndex(e.index)}
        responsiveOptions={responsiveOptions}
        numVisible={5}
        circular
        style={{ maxWidth: "800px" }}
        item={itemTemplate}
        className="tumbnail-container"
        thumbnail={thumbnailTemplate}
      />
    </div>
  );
}

export default Fileviewer;
